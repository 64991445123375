var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-card', {
    staticClass: "box-card",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix flex justify-content-end",
    staticStyle: {
      "gap": "8px"
    },
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('el-input', {
    staticStyle: {
      "width": "30%",
      "margin-right": "auto"
    },
    attrs: {
      "placeholder": "Type to search product name",
      "clearable": ""
    },
    on: {
      "input": _vm.handleKeywordChanges
    },
    model: {
      value: _vm.searchKeyword,
      callback: function ($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  }), _c('el-button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "icon": "el-icon-plus"
    },
    on: {
      "click": function ($event) {
        _vm.isNewProductDialogOpen = !_vm.isNewProductDialogOpen;
      }
    }
  }, [_vm._v(" New File ")])], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.defaultFileTable
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'product-file.detail',
              params: {
                id: scope.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.name) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "label": "Type"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "total",
      "label": "Total files"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "updated_at",
      "label": "Updated at"
    }
  }), _c('el-table-column', {
    staticClass: "flex",
    staticStyle: {
      "gap": "16px"
    },
    attrs: {
      "align": "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          staticClass: "mr-2",
          attrs: {
            "slot": "reference",
            "size": "small"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push({
                name: 'product-file.detail',
                params: {
                  id: scope.row.id
                }
              });
            }
          },
          slot: "reference"
        }, [_c('mdicon', {
          attrs: {
            "name": "pencil",
            "size": 20
          }
        })], 1), _c('el-popconfirm', {
          attrs: {
            "confirm-button-text": "Yes, delete",
            "cancel-button-text": "No, cancel",
            "confirm-button-type": "danger",
            "icon": "el-icon-info",
            "icon-color": "red",
            "title": "Are you sure to delete this?"
          },
          on: {
            "confirm": function ($event) {
              return _vm.handleDelete(scope.$index, scope.row);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "slot": "reference",
            "size": "small"
          },
          slot: "reference"
        }, [_c('mdicon', {
          staticClass: "text-danger",
          attrs: {
            "name": "trash-can",
            "size": 20
          }
        })], 1)], 1)];
      }
    }])
  })], 1), _c('Pagination', {
    attrs: {
      "total_data": _vm.totalTableData,
      "limit": _vm.limit,
      "current": _vm.currentPage
    },
    on: {
      "update-table-page": _vm.handleTablePage,
      "update-table-limit": _vm.handleTableLimit
    }
  })], 1), _c('NewDefaultFileModal', {
    model: {
      value: _vm.isNewProductDialogOpen,
      callback: function ($$v) {
        _vm.isNewProductDialogOpen = $$v;
      },
      expression: "isNewProductDialogOpen"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }