var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Add file",
      "visible": _vm.isModalOpen,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.isModalOpen = $event;
      },
      "closed": _vm.handleDialogClosed
    }
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "form": _vm.tradeProductDefaultFileForm,
      "model": _vm.tradeProductDefaultFileForm,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Trade Product",
      "prop": "product_uuid"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "clearable": "",
      "placeholder": "Enter a keyword to search trade products",
      "remote-method": _vm.fetchTradeProducts,
      "loading": _vm.isLoading
    },
    model: {
      value: _vm.tradeProductDefaultFileForm.product_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.tradeProductDefaultFileForm, "product_uuid", $$v);
      },
      expression: "tradeProductDefaultFileForm.product_uuid"
    }
  }, _vm._l(_vm.products, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.uuid
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-upload', {
    ref: "upload",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "action": "null",
      "drag": "",
      "on-change": _vm.handleChangedFile,
      "on-remove": _vm.handleRemovedFile,
      "auto-upload": false,
      "multiple": "",
      "file-list": _vm.tradeProductDefaultFileForm.documents,
      "accept": ".png, .gif, .jpg, .jpeg, .bmp, .csv, .txt, .xlx, .xls, xlsx, .doc, .docx, .ppt, .pptx, .pdf"
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v(" Drop file here or "), _c('em', [_vm._v("click to upload")])]), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(" Accept files with extension .png, .gif, .jpg, .jpeg, .bmp, .csv, .txt, .xlx, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf ")])])], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.isBtnLoading,
      "disabled": _vm.tradeProductDefaultFileForm.documents.length < 1
    },
    on: {
      "click": function ($event) {
        return _vm.submitNewProduct('form');
      }
    }
  }, [_vm._v(" Add ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }